import type { QueryFunctionContext } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { minutes } from '~/helpers/time.helper';
import { sharedQueryClient } from '~/libs/queries/use-query-client';
import useUser from '~/libs/use-user';
import { Bff } from '~/models/bff';
import { QueryKeys, queryKeys } from '../../query-keys';
import { IPrefetch } from '../../types';
import { getContentUrl } from '../utils/get-content-url';
import { apiClient } from '~/services/api-client';
import { Session } from 'next-auth';

// User-specific data
export function useStaticContent<SelectType = IStaticContent>(select?: (data: IStaticContent) => SelectType) {
    const { user } = useUser();
    const { locale: currentLocale, defaultLocale } = useRouter();
    const locale = currentLocale || defaultLocale;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const query = useQuery<IStaticContent, Error, SelectType>(queryKeys.bff.staticContent(locale, user), fetchStaticContent(user), {
        retry: 1,
        staleTime: minutes(10),
        cacheTime: minutes(20),
        select,
        enabled: user?.isLoggedIn,
        keepPreviousData: true,
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
    });

    return {
        data: query.data,
        ...query?.data, // TODO: Remove this temporary solution
        isFetched: query.isFetched,
        isSuccess: query.isSuccess,
    };
}

// Non user-specific data
export async function prefetchStaticContent({ locale, queryClient }: IPrefetchProps) {
    const staticContentKey = queryKeys.bff.staticContent(locale as string);

    await sharedQueryClient.fetchQuery(staticContentKey, fetchStaticContent(), {
        staleTime: minutes(10),
    });

    return queryClient.setQueryData(staticContentKey, () => sharedQueryClient.getQueryData(staticContentKey), {
        updatedAt: sharedQueryClient.getQueryState(staticContentKey)?.dataUpdatedAt,
    });
}

function fetchStaticContent(user?: Session) {
    return function (context: QueryFunctionContext<QueryKeys['bff']['staticContent']>) {
        const { signal, queryKey } = context;
        const [, , { locale = '' }] = queryKey;
        const url = getContentUrl('/staticcontent');
        url.searchParams.set('lang', locale);

        if (user) {
            return apiClient.auth(user).get(url, { signal });
        } else {
            return apiClient.get(url, { signal });
        }
    };
}

type IStaticContent = Bff.IStaticContent;

interface IPrefetchProps extends IPrefetch {
    locale: string | undefined;
}
